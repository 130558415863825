<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="修改账号"
    width="800px"
    :closeOnClickModal="false"
    @closed="clearForm"
  >
    <div class="form_box">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="formRef"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="运营商">
              <el-input disabled :value="ruleForm.name_operator" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="项目">
              <el-input disabled :value="ruleForm.name_project" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="用户名" prop="user_name">
              <el-input :value="ruleForm.user_name" disabled></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="姓名" prop="real_name">
              <el-input v-model.trim="ruleForm.real_name"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="联系方式" prop="tel">
              <el-input v-model="ruleForm.tel" maxlength="11"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="重置密码" prop="pwd">
              <el-input
                v-model="ruleForm.pwd"
                type="password"
                maxlength="24"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="确认密码" prop="confirm_pwd">
              <el-input
                v-model="ruleForm.confirm_pwd"
                type="password"
                maxlength="24"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="账户角色" prop="role">
              <el-select
                v-model="ruleForm.role"
                placeholder="请选择账户角色"
                class="w100"
                disabled
              >
                <el-option label="项目管理员" :value="2"></el-option>
                <el-option label="楼栋管理员" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <p class="tip" v-if="ruleForm.role === 3">仅建立移动端账号</p>
          </el-col>
        </el-row>
      </el-form>

      <div class="distribution_build" v-if="ruleForm.role === 3">
        <div class="desc" @click="showInnerDialog">分配楼栋</div>
        <div class="build_list">
          <span v-for="(item, index) in selectedList" :key="item.id"
            >{{ item.name_building }}{{
            }}{{ selectedList.length - 1 > index ? '、' : '' }}</span
          >
        </div>
      </div>
    </div>

    <!-- 内嵌弹窗 start -->
    <el-dialog
      :visible.sync="innerDialogVisible"
      title="选择该移动端账号管理的楼栋"
      width="678px"
      :closeOnClickModal="false"
      :appendToBody="true"
    >
      <div class="inner_container">
        <div class="item_title">
          <div class="right">
            <el-checkbox v-model="isSelectAll">全选</el-checkbox>
          </div>
        </div>
        <div class="item_content change_account_dialog">
          <el-scrollbar style="height: 100%;">
            <div class="item" v-for="item in buildingList" :key="item.id">
              <div class="left">{{ item.name_building }}</div>
              <div class="right">
                <el-checkbox v-model="item.selected"></el-checkbox>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="innerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="innerSave">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 内嵌弹窗 end -->

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  check_name_chief,
  check_tel,
  _check_pwd,
  check_role
} from '@/utils/validator.js'
import MD5 from 'md5'
import { GetNoAdminBuildListApi, UpdateAccountApi } from '@/api'

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 用户的权限级别
      role: '',
      // 表单
      ruleForm: {
        id: '',
        id_operator: '',
        id_project: '',
        name_project: '',
        real_name: '',
        tel: '',
        user_name: '',
        pwd: '',
        confirm_pwd: '',
        role: ''
      },
      // 规则
      rules: {
        real_name: check_name_chief,
        tel: check_tel,
        pwd: _check_pwd,
        confirm_pwd: _check_pwd,
        role: check_role
      },
      // 内嵌dialog是否显示
      innerDialogVisible: false,
      // 当前用户拥有的楼栋列表
      hasBuild: [],
      // 渲染楼栋列表
      buildingList: [],
      // 选中的楼栋列表
      selectedList: []
    }
  },
  methods: {
    // 编辑账号
    edit(row) {
      this.role = row.role
      this.dialogVisible = true
      this.ruleForm.id = row.id
      this.ruleForm.name_operator = row.name_operator
      this.ruleForm.id_project = row.id_project
      this.ruleForm.name_project = row.name_project
      this.ruleForm.user_name = row.user_name
      // 处理用户拥有的楼栋列表
      this.hasBuild = row.build
      this.selectedList = row.build
      this.$nextTick(() => {
        this.ruleForm.real_name = row.real_name
        this.ruleForm.tel = row.tel
        this.ruleForm.role = row.role
      })
    },

    // 点击保存
    save() {
      this.$refs.formRef.validate(_ => {
        if (!_) return
        if (this.role === 3 && this.selectedList.length === 0) {
          return this.$message.warning('请勾选楼栋信息!')
        }
        this.editAccount()
      })
    },

    // 编辑帐号
    async editAccount() {
      const form = this.ruleForm
      if ((form.pwd || form.confirm_pwd) && form.pwd !== form.confirm_pwd) {
        return this.$message.warning('两次输入的密码不一致')
      }
      const params = {
        id_user: form.id,
        real_name: form.real_name.trim(),
        tel: form.tel
      }
      const ids = []
      if (this.selectedList.length) {
        this.selectedList.map(i => {
          ids.push(i.id)
        })
      }
      if (ids.length) {
        params.build_array = ids.join(',')
      }
      if (form.pwd) {
        params.pwd = MD5(form.pwd)
      }
      const { ret, data, msg } = await UpdateAccountApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.dialogVisible = false
      this.$message.success('更新成功')
      this.$emit('refresh')
    },

    // 显示内嵌弹窗
    async showInnerDialog() {
      const params = {
        id_project: this.ruleForm.id_project
      }
      const { ret, data, msg } = await GetNoAdminBuildListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      const selectedIds = Array.from(this.selectedList, (it, idx) => {
        return it.id
      })
      const buildList = []
      this.hasBuild.map(i => {
        if (selectedIds.includes(i.id)) {
          buildList.push({
            id: i.id,
            name_building: i.name_building,
            selected: true
          })
        } else {
          buildList.push({
            id: i.id,
            name_building: i.name_building,
            selected: false
          })
        }
      })
      data.map(i => {
        if (selectedIds.includes(i.id)) {
          buildList.push({
            ...i,
            selected: true
          })
        } else {
          buildList.push({
            ...i,
            selected: false
          })
        }
      })
      this.buildingList = buildList
      this.innerDialogVisible = true
    },

    // 内嵌弹窗 保存
    innerSave() {
      this.selectedList = this.buildingList.filter(i => i.selected)
      this.innerDialogVisible = false
    },

    // 清除表单校验
    clearForm() {
      try {
        this.$refs.formRef.resetFields()
      } catch (e) {}
      this.buildingList = []
      this.selectedList = []
    }
  },

  computed: {
    // 计算是否全部选中
    isSelectAll: {
      get() {
        const len = this.buildingList.length
        const selectedList = this.buildingList.filter(i => i.selected)
        return selectedList.length === len
      },
      set(flag) {
        this.buildingList.map(i => {
          i.selected = flag
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form_box {
  padding: 16px 36px;

  .tip {
    padding-left: 32px;
    font-size: 14px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    color: #ff5777;
  }
}

.distribution_build {
  display: flex;

  .desc {
    flex: 0 0 100px;
    padding-right: 12px;
    font-size: 14px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    text-decoration: underline;
    color: #50e399;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  .build_list {
    min-height: 40px;
    flex: 1;
    border: 1px solid #dcdfe6;
    border-radius: 10px;
    padding: 12px 25px;
  }
}

.inner_container {
  height: 380px;
  padding: 16px 80px 16px 98px;

  .item_title {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .right {
      width: 60px;
    }
  }

  .item_content {
    height: 300px;
    width: 100%;

    .item {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:hover {
        .left {
          color: #0062ff;
        }
      }

      .left {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #12203e;
      }

      .right {
        width: 60px;
      }
    }
  }
}
</style>
<style lang="scss">
.change_account_dialog {
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>

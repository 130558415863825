<template>
  <div class="account">
    <div class="card">
      <!-- 标题 -->
      <div class="title_box">
        <div></div>
        <span>账号列表</span>
      </div>
      <!-- 选择器 -->
      <div class="selector">
        <div class="selects">
          <el-select
            v-model="id_operator"
            placeholder="全部运营商"
            style="margin-right: 14px"
            @change="id_project = ''"
            class="w200"
            v-supAdmin
          >
            <el-option
              v-for="item in operatorList"
              :key="item.id_operator"
              :label="item.name_operator"
              :value="item.id_operator"
            >
            </el-option>
          </el-select>

          <el-select
            v-model="id_project"
            placeholder="全部项目"
            style="margin-right: 14px"
            class="w200"
          >
            <el-option
              v-for="item in computedProject"
              :key="item.id"
              :label="item.name_project"
              :value="item.id"
            >
            </el-option>
          </el-select>

          <el-input
            class="input w200"
            placeholder="输入用户名"
            v-model="user_name"
            prefix-icon="el-icon-search"
          ></el-input>

          <el-button
            type="success"
            style="margin: 0 6px 0 20px"
            icon="el-icon-search"
            @click="search"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh-left" @click="reset"
            >重置</el-button
          >
        </div>
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="addAccount"
            >添加账号</el-button
          >
        </div>
      </div>

      <!-- 表格 -->
      <div class="table_box">
        <el-table
          :data="accountList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb'
          }"
          size="small"
        >
          <el-table-column label="用户名" prop="user_name"></el-table-column>
          <el-table-column label="姓名" prop="real_name"></el-table-column>
          <el-table-column
            v-if="level === 100"
            label="运营商"
            prop="name_operator"
          ></el-table-column>
          <el-table-column label="项目" prop="name_project"></el-table-column>
          <el-table-column label="联系方式" prop="tel"></el-table-column>
          <el-table-column label="账号类型">
            <template v-slot="scope">
              <span>
                {{ scope.row.role | roleFmt }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <div class="table_control">
                <img
                  @click="editAccount(scope.row)"
                  src="../../assets/table_edit.png"
                  alt=""
                  title="编辑"
                />
                <img
                  @click="showDelAlert(scope.row)"
                  src="../../assets/table_delete.png"
                  alt=""
                  title="删除"
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页功能 -->
      <div class="pages">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentPageChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加账号 -->
    <AddAccount ref="addAccountRef" @refresh="getList" />

    <!-- 编辑账号 -->
    <EditAccount ref="editAccountRef" @refresh="getList" />

    <Alert ref="alertRef" @confirm="delAccount" />
  </div>
</template>

<script>
import { GetUserListApi, DelAccountApi } from '@/api'
import AddAccount from './component/add_account.vue'
import EditAccount from './component/edit_account.vue'
import { roleFmt } from '@/utils/filter.js'
import operator from '@/mixins/operator.js'
import project from '@/mixins/project.js'
import Alert from '@/components/alert/alert.vue'

export default {
  name: 'account',
  mixins: [operator, project],
  components: { AddAccount, Alert, EditAccount },
  data() {
    return {
      // 权限级别
      level: '',
      id_operator: '',
      // 项目
      id_project: '',
      // 用户名
      user_name: '',
      // 账号列表
      accountList: [],
      // 分页相关
      total: 0,
      page_id: 0,
      page_num: 20
    }
  },
  methods: {
    // 分页尺寸变化
    sizeChange(size) {
      this.page_num = size
      this.getList()
    },
    // 当前页变化
    currentPageChange(page) {
      this.page_id = page - 1
      this.getList()
    },

    // 点击查询
    search() {
      this.page_id = 0
      this.getList()
    },

    // 重置
    reset() {
      this.page_id = 0
      this.id_operator = ''
      this.id_project = ''
      this.user_name = ''
      this.getList()
    },

    // 获取用户列表
    async getList() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      if (this.id_project) {
        params.id_project = this.id_project
      }
      if (this.user_name.trim()) {
        params.user_name = this.user_name.trim()
      }
      const { ret, data, msg } = await GetUserListApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.total = data.cnt_all
      this.accountList = data.data
    },

    // 添加账号
    addAccount() {
      this.$refs.addAccountRef.add(this.operatorList, this.projectList)
    },

    // 点击删除
    showDelAlert(row) {
      this.$refs.alertRef.alert(
        {
          title: '删除提醒',
          msg: '您确定要删除该账号吗? 此操作不可恢复'
        },
        row,
        1
      )
    },

    // 删除账号
    async delAccount({ info, type }) {
      const params = { id_user: info.id }
      const { ret, data, msg } = await DelAccountApi(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.$message.success('删除成功')
      this.getList()
    },

    // 编辑账号
    editAccount(row) {
      this.$refs.editAccountRef.edit(row)
    }
  },

  created() {
    this.level = this.$store.getters.level
    if (this.level === 100) {
      this.getOperatorList()
    }
    this.getProjectList()
    this.getList()
  },

  computed: {
    computedProject() {
      if (this.id_operator) {
        return this.projectList.filter(i => i.id_operator === this.id_operator)
      } else {
        return this.projectList
      }
    }
  },

  filters: {
    roleFmt
  }
}
</script>

<style lang="scss" scoped>
.account {
  padding: 0 22px;
}

.card {
  min-height: calc(100vh - 160px);
  margin-top: 24px;

  .title_box {
    height: 48px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebedf2;

    div {
      width: 4px;
      height: 18px;
      background: linear-gradient(-75deg, #50e379, #50e398);
      box-shadow: 0px 3px 4px 0px rgba(80, 227, 146, 0.35);
      margin: 0 11px 0 26px;
    }

    span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #12203e;
    }
  }

  .selector {
    padding: 20px 22px;
    display: flex;
    justify-content: space-between;
  }

  .table_box {
    padding: 0 22px;
  }
}
</style>
